import React from "react";

const About = () => {
  return (
    
    <div
      name="about"
      className="w-full bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>

        <p className="text-xl mt-10">
          Hello, my name is Burak Bulama. Over the past 4 years, I have been
          dedicated to enhancing my skills in machine learning and deep
          learning. Throughout this journey, I delved deeply into the
          theoretical underpinnings of machine learning and deep learning
          algorithms, and I put this knowledge into practice through various
          small-scale artificial intelligence projects.
          <br />
          <br />
          I extensively utilized popular frameworks such as TensorFlow and
          PyTorch, with a specific emphasis on PyTorch. My expertise in machine
          learning also extends to data analysis using scikit-learn.
          <br />
          <br />
          My learning journey has been project-centric, particularly in the
          domains of computer vision and machine learning. Notable projects
          include a TÜBİTAK research project on industrial security using
          computer vision, an automatic screen translation software for Windows
          computers, and a text recognition software for the IEEE Firat
          University Club. Additionally, I've worked on numerous smaller-scale
          projects to reinforce my skills.
          <br />
          <br />
          After gaining proficiency in computer vision for approximately 2
          years, I transitioned my focus to natural language processing (NLP).
          I deepened my theoretical understanding through various courses and
          honed my skills by developing NLP projects using TensorFlow and
          PyTorch. Projects in this realm include an automatic writing assistant
          and a personalized chatbot. Again, I supplemented my learning with
          smaller-scale projects.
          <br />
          <br />
          For the past 2 years, my attention has been on Generative Artificial
          Intelligence models. I developed my own GPT (Generative Pre-trained
          Transformer) model using PyTorch and designed a chatbot. Additionally,
          I developed a Vision Transformer model that interprets images using
          the Transformer architecture. Presently, I am engaged in developing
          and deploying larger-scale language models on cloud systems, utilizing
          Google Cloud services for project deployment and model training.
          <br />
          <br />
          My current aspiration is to build a career in advanced artificial
          intelligence, with a particular focus on Generative models.
          <br />
          <br />
          Additionally, for the past 1.5 years, I have been one of the
          co-founders of <a className="text-blue-500" href="https://www.teteatalk.com" target="_blank" rel="noopener noreferrer">TeteaTalk</a>, a venture aimed at practicing foreign
          languages by conversing with artificial intelligence and 3D human
          models.
        </p>
      </div>
    </div>
  );
};

export default About;
